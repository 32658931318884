import * as React from 'react'
import { Box } from '@mui/material'

const ErrorPage = () => {
    return <Box sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
    }}>
        Something went wrong
    </Box>
}

export default ErrorPage